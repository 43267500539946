import Vue from 'vue';
import router from './scripts/router.js'
import App from './App.vue';
// import '@/styles/custom.scss';
import BootstrapVue from 'bootstrap-vue';
import siteHelpers from '@/scripts/helpers/siteHelpers.js';

siteHelpers.importCSS();

Vue.config.productionTip = false

// Bootstrap
Vue.use(BootstrapVue);

new Vue({
  template: '<App/>',
  components: { App },
  router,
  beforeMount: function () {
    Vue.prototype.$app = this;
  },
  render: h => h(App),
}).$mount('#app');
