export default {
  //defaultSiteName: 'howdoyourecycle',
  defaultSiteName: 'howmanystamps',
  ads: {
    adClient: 'ca-pub-0734262141491051',
    slots: {
      footer: '6346950498',
      pageIntro: '2037536842'
    }
  }
};