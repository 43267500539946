import env from '@/env.js';

var siteEnv;

export default {
    getSiteEnv: function () {
        if (!siteEnv) {
            siteEnv = this.loadScript('env.js')[0];
        }
        return siteEnv;
    },
    getEnvValue: function (key) {
        return this.getSiteEnv()[key];
    },
    getSiteName: function () {
        var hostname = window.location.hostname;

        if (hostname === 'localhost') {
            hostname = env.defaultSiteName;
        }

        return hostname.indexOf('.') > 0 ? hostname.substring(0, hostname.indexOf('.')) : hostname;
    },
    importCSS: function () {
        import(`@/sites/${this.getSiteName()}/styles.scss`);
    },
    isDev: function () {
        return window.location.hostname == 'localhost';
    },
    // Returns the specified script from the current site folder
    loadScript: function (script) {
        var context;

        context = require.context('@/sites', true, /.+.js$/i);
        return this.processFileToLoad(context, script, true);
    },
    // Returns the specified JSON file from the current site folder
    loadJson: function (script) {
        var context,
            result;

        context = require.context('@/sites', true, /.+.json$/i);
        result = this.processFileToLoad(context, script);
        result = result ? result[0] : null;

        return result;
    },
    processFileToLoad: function (context, script, mapDefault = false) {
        var result,
            keys;

        keys = context.keys();

        for (var key of keys) {
            if (key.indexOf(`/${this.getSiteName()}/`) >= 0 && key.indexOf(script) > 0) {
                result = [key].map(context);

                if (mapDefault) {
                    result = result.map(m => m.default);
                }
                break;
            }
        }

        return result;
    }
};